<template>
    <div class="lineworks-setting-result">
        <div class="container">
            <div class="m-5">

                <div class="title p-5">
                    <h3 class="text-center">クラウドサイン - LINE WORKS連携<br>通知ボット管理</h3>
                    <h3 class="text-center mb-4">連携設定</h3>
                </div>

                <b-card bg-variant="light">
                    <div class="mb-2">
                        <h6 class="m-2 text-danger mt-4" v-if="registered">
                            API連携が完了しました。<br>
                            クラウドサインの管理画面>チーム設定画面>「Hook URL」には以下のURLを設定してください。<br><br>
                            クラウドサインHook URL<br>
                            {{hookUrl}}<br><br>
                        </h6>
                        <h6 class="m-2">詳細は<b-link href="https://help.cloudsign.jp/ja/articles/5055112" class="text-primary" target="_blank">連携マニュアル</b-link>をご覧ください。</h6>
                    </div>

                    <br>
                    <div class="border-bottom" />
                    <br>

                    <b-button class="w-100" :to="{ path: '/' }" variant="primary">連携設定ページへ戻る</b-button>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LineworksSettingResult",
    data() {
        return {
            registered: false,
            hookUrl: ''
        }
    },
    computed: {
    },
    methods: {
        getLineworksSetting(){
            console.log(this.$store.state.user)

            if(this.$store.state.user){
                let url = process.env.VUE_APP_API_BASE_URL + '/lineworks-setting/get'

                let data = {
                    'adminUserId': this.$store.state.user.email
                }

                this.axios.post(url, data)
                .then(function (response) {
                    console.log(response);
                    if (response['data']['result'] && response['data']['message']){
                        this.registered = true
                        this.hookUrl = response['data']['message']
                    }
                    else if (response['data']['message']){
                        this.showAlertDanger('通知ボット連携設定取得に失敗しました。管理者へお問合せください。')
                        this.$router.replace('/signout')
                    }else{
                        this.registered = false
                    }

                }.bind(this))
                .catch(function (err) {
                    console.log(err);
                    this.showAlertDanger('通知ボット連携設定取得に失敗しました。管理者へお問合せください。')
                    this.$router.replace('/signout')
                    this.registered = false
                }.bind(this))
            }
        }
    },
    watch:  {
        '$store.state.user': function(){
            if (this.$store.state.user){
                this.getLineworksSetting()
            }
        }
    }
}
</script>
