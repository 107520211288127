<template>
    <div class="notification-alert">
        <div class="container">
            <b-alert :show="showAlert" dismissible :variant="variant" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
                {{message}}
            </b-alert>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotificationAlert",
    data() {
        return {
            dismissCountDown: 0,
            second: '',
            variant: '',
            message: ''
        }
    },
    computed: {
        showAlert(){
            return this.showAlertCheck()
        }
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
            if(this.dismissCountDown == 0){
                this.$store.commit('resetAlert')
            }
        },
        showAlertCheck() {
            this.second = this.$store.state.alert.second
            this.variant = this.$store.state.alert.variant
            this.message = this.$store.state.alert.message
            return this.second
        }
    }
};
</script>
<style>
.alert {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    margin: 20px !important;
}
</style>
