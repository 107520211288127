<template>
    <div class="signin-view">
        <Signin />
    </div>
</template>

<script>
import Signin from "@/components/Signin.vue";

export default {
    name: "SigninView",
    components: {
        Signin,
    },
};
</script>
