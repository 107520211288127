<template>
    <div class="signup-view">
        <Signup />
    </div>
</template>

<script>
import Signup from "@/components/Signup.vue";

export default {
    name: "SignupView",
    components: {
        Signup,
    },
};
</script>
