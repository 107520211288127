<template>
    <div class="footer">
        <footer class="mt-auto py-3 bg-light">
            <div class="footer-text">
                <span class="text-copywriter"> © 2021 Bengo4.com, Inc.</span>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: "Footer"
};
</script>

<style>
.footer-text {
  width: auto;
  padding: 0 15px;
  text-align: center;
}
</style>
