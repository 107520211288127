import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user: null,
        alert: {
            second: 0,
            variant: '',
            message: ''
        }
    },
    getters: {
        user: state => state.user,
        alert: state => state.alert
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        resetUser(state) {
            state.user = null
        },
        setAlert(state, alert) {
            state.alert = alert
        },
        resetAlert(state) {
            let alert = {
                second: 0,
                variant: '',
                message: ''
            }
            state.alert = alert
        }
    }
});

export default store
