<template>
    <div class="signup">
        <div class="container">
            <div class="m-5">

                <div class="title p-5">
                    <h3 class="text-center">クラウドサイン - LINE WORKS連携<br>通知ボット管理</h3>
                    <h3 class="text-center mb-4">新規アカウント登録</h3>
                </div>

                <b-card bg-variant="light">
                    <div class="mb-3">
                        <h4 class="text-center text-danger mt-4 mb-5">注意事項</h4>
                        <h6 class="m-2">
                            1つのLINE WORKSのテナントに対して通知ボット設定管理サイトのアカウントは１つだけしか発行できません。<br><br>
                            ※ 複数メンバーで共有可能なグループメールアドレス等によるアカウント発行を推奨します。<br>
                            ※ アカウントを複数作成して連携設定を行った場合、予期せぬ動作をする可能性があります。<br><br>
                            LINE WORKS連携サービスの利用にあたってはクラウドサイン利用規約における「本サービス」に含まれるものとして当該規約が適用されますので、
                            当該規約（<b-link href="https://www.cloudsign.jp/tos" class="text-primary" target="_blank">https://www.cloudsign.jp/tos</b-link>）ならびに<br>
                            プライバシーポリシー（<b-link href="https://www.cloudsign.jp/privacy" class="text-primary" target="_blank">https://www.cloudsign.jp/privacy</b-link>）
                            に同意した上でアカウントの新規登録をお願いいたします。
                        </h6>
                    </div>

                    <br>
                    <div class="border-bottom" />
                    <br>

                    <b-form @submit.prevent="signup">

                        <b-form-group id="input-group-name" label="会社名" label-for="input-name">
                            <b-form-input id="input-name" v-model="name" :state="nameCheck" type="text" placeholder="会社名"></b-form-input>
                            <b-form-invalid-feedback id="input-name-invalid-feedback">{{nameMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-name-valid-feedback">{{nameMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-email" label="メールアドレス" label-for="input-email">
                            <b-form-input id="input-email" v-model="email" :state="emailCheck" type="email" placeholder="メールアドレス"></b-form-input>
                            <b-form-invalid-feedback id="input-email-invalid-feedback">{{emailMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-email-valid-feedback">{{emailMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-email-confirm" label="メールアドレス確認" label-for="input-email-confirm">
                            <b-form-input id="input-email-confirm" v-model="emailConfirm" :state="emailConfirmCheck" type="email" placeholder="メールアドレス確認"></b-form-input>
                            <b-form-invalid-feedback id="input-email-confirm-invalid-feedback">{{emailConfirmMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-email-confirm-valid-feedback">{{emailConfirmMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-password" label="パスワード" label-for="input-password">
                            <b-form-input id="input-password" v-model="password" :state="passwordCheck" type="password" placeholder="パスワード"></b-form-input>
                            <b-form-invalid-feedback id="input-password-invalid-feedback">{{passwordMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-password-valid-feedback">{{passwordMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-password-confirm" label="パスワード確認" label-for="input-password-confirm">
                            <b-form-input id="input-password-confirm" v-model="passwordConfirm" :state="passwordConfirmCheck" type="password" placeholder="パスワード確認"></b-form-input>
                            <b-form-invalid-feedback id="input-password-confirm-invalid-feedback">{{passwordConfirmMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-password-confirm-valid-feedback">{{passwordConfirmMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-button class="w-100" v-bind:disabled="submitCheck" type="submit" variant="primary">次へ</b-button>
                    </b-form>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Signup',
    data() {
        return {
            name: '',
            nameMassage: '',
            email: '',
            emailMassage: '',
            emailConfirm: '',
            emailConfirmMassage: '',
            password: '',
            passwordMassage: '',
            passwordConfirm: '',
            passwordConfirmMassage: '',
        }
    },
    computed: {
        nameCheck() {
            return this.nameValidation()
        },
        emailCheck() {
            return this.emailValidation()
        },
        emailConfirmCheck() {
            return this.emailConfirmValidation()
        },
        passwordCheck() {
            return this.passwordValidation()
        },
        passwordConfirmCheck() {
            return this.passwordConfirmValidation()
        },
        submitCheck() {
            return this.submitValidation()
        }
    },
    methods: {
        signup() {
            if (this.email && (this.password === this.passwordConfirm)) {
                this.$cognito.signUp(this.email, this.password, this.name)
                    .then(result => {
                        console.log(result)
                        localStorage.setItem('email', this.email);
                        localStorage.setItem('path', '/signup-confirm')
                        this.$router.replace('/signup-confirm')
                    })
                    .catch(err => {
                        console.log(err)
                        this.showAlertDanger('アカウントの作成に失敗しました。 入力されたメールアドレスは既に登録されています。')
                    })
            }
        },
        nameValidation() {
            let state = false
            this.nameMassage = '必須入力です'

            if (this.name) {
                this.nameMassage = '問題なし'
                state = true
            }
            return state
        },
        emailValidation() {
            this.emailMassage = '問題なし'
            let state = true

            let regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

            if(!this.email){
                this.emailMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.email)) {
                this.emailMassage = 'メールアドレスのフォーマットが不正です'
                state = false
            }

            return state
        },
        emailConfirmValidation() {
            this.emailConfirmMassage = '問題なし'
            let state = true

            let regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

            if (!this.emailConfirm) {
                this.emailConfirmMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.emailConfirm)) {
                this.emailConfirmMassage = 'メールアドレスのフォーマットが不正です'
                state = false
            }
            else if (this.email != this.emailConfirm){
                this.emailConfirmMassage = 'メールアドレスが一致しません'
                state = false
            }

            return state
        },
        passwordValidation() {
            this.passwordMassage = '問題なし'
            let state = true

            let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[!-~]{8,100}$/;

            if (!this.password) {
                this.passwordMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.password)){
                this.passwordMassage = '半角小文字、大文字、数字をそれぞれ1種類以上含む 8文字以上のパスワードを入力してください'
                state = false
            }

            return state
        },
        passwordConfirmValidation() {
            this.passwordConfirmMassage = '問題なし'
            let state = true

            let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[!-~]{8,100}$/;

            if (!this.passwordConfirm) {
                this.passwordConfirmMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.passwordConfirm)){
                this.passwordConfirmMassage = '半角小文字、大文字、数字をそれぞれ1種類以上含む 8文字以上のパスワードを入力してください'
                state = false
            }
            else if (this.password != this.passwordConfirm){
                this.passwordConfirmMassage = 'パスワードが一致しません'
                state = false
            }

            return state
        },
        submitValidation() {
            let disabled = true
            if (this.nameValidation() && this.emailValidation() && this.emailConfirmValidation() && this.passwordValidation() && this.passwordConfirmValidation()) {
                disabled = false
            }

            return disabled
        }
    }
}
</script>
