export default {
    methods: {
        showAlertPrimary(message) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            let alert = {
                second: 5,
                variant: 'primary',
                message: message
            }
            this.$store.commit('setAlert', alert)
        },
        showAlertSecondary(message) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            let alert = {
                second: 5,
                variant: 'secondary',
                message: message
            }
            this.$store.commit('setAlert', alert)
        },
        showAlertSuccess(message) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            let alert = {
                second: 5,
                variant: 'success',
                message: message
            }
            this.$store.commit('setAlert', alert)
        },
        showAlertDanger(message) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            let alert = {
                second: 5,
                variant: 'danger',
                message: message
            }
            this.$store.commit('setAlert', alert)
        },
        showAlertWarning(message) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            let alert = {
                second: 5,
                variant: 'warning',
                message: message
            }
            this.$store.commit('setAlert', alert)
        },
        showAlertInfo(message) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            let alert = {
                second: 5,
                variant: 'info',
                message: message
            }
            this.$store.commit('setAlert', alert)
        },
        showAlertLight(message) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            let alert = {
                second: 5,
                variant: 'light',
                message: message
            }
            this.$store.commit('setAlert', alert)
        },
        showAlertDark(message) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            let alert = {
                second: 5,
                variant: 'dark',
                message: message
            }
            this.$store.commit('setAlert', alert)
        }
    }
}
