<template>
    <div class="reset-password">
        <div class="container">
            <div class="m-5">

                <div class="title p-5">
                    <h3 class="text-center">クラウドサイン - LINE WORKS連携<br>通知ボット管理</h3>
                    <h3 class="text-center mb-4">パスワード再設定</h3>
                </div>

                <b-card bg-variant="light">
                    <div class="mb-2">
                        <h4 class="text-center mt-4 mb-5">パスワード再設定</h4>
                        <h6 class="m-2">
                            登録しているメールアドレスを入力し、『パスワード再設定』を選択してください。
                        </h6>
                    </div>

                    <br>
                    <div class="border-bottom" />
                    <br>

                    <b-form @submit.prevent="forgetPassword">

                        <b-form-group id="input-group-email" label="メールアドレス" label-for="input-email">
                            <b-form-input id="input-email" v-model="email" :state="emailCheck" type="email" placeholder="メールアドレス"></b-form-input>
                            <b-form-invalid-feedback id="input-email-invalid-feedback">{{emailMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-email-valid-feedback">{{emailMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-button class="w-100" v-bind:disabled="submitCheck" type="submit" variant="primary">次へ</b-button>
                    </b-form>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ResetPassword',
    data() {
        return {
            email: '',
            emailMassage: ''
        }
    },
    computed: {
        emailCheck() {
            return this.emailValidation()
        },
        submitCheck() {
            return this.submitValidation()
        }
    },
    methods: {
        forgetPassword() {
            this.$cognito.forgetPassword(this.email)
                .then(result => {
                    console.log(result)
                    localStorage.setItem('email', this.email);
                    localStorage.setItem('path', '/reset-password-confirm')
                    this.$router.replace('/reset-password-confirm')
                })
                .catch(err => {
                    console.log(err)
                    this.showAlertDanger('確認コード送信に失敗しました。メールアドレスを入力した上で、再送信をクリックしてください。')
                })
        },
        emailValidation() {
            this.emailMassage = '問題なし'
            let state = true

            let regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

            if(!this.email){
                this.emailMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.email)) {
                this.emailMassage = 'メールアドレスのフォーマットが不正です'
                state = false
            }

            return state
        },
        submitValidation() {
            let disabled = true
            if (this.emailValidation()) {
                disabled = false
            }

            return disabled
        }
    }
}
</script>
