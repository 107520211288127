<template>
    <div class="reset-password-confirm-view">
        <ResetPasswordConfirm />
    </div>
</template>

<script>
import ResetPasswordConfirm from "@/components/ResetPasswordConfirm.vue";

export default {
    name: "ResetPasswordConfirmView",
    components: {
        ResetPasswordConfirm,
    },
};
</script>
