<template>
    <div class="not-found-view">
        <NotFound />
    </div>
</template>

<script>
import NotFound from "@/components/NotFound.vue";

export default {
    name: "NotFoundView",
    components: {
        NotFound,
    },
};
</script>
