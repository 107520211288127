<template>
    <div class="not-found">
        <div class="container">
            <div class="m-5">

                <div class="title p-5">
                    <h1 class="text-center mb-4">NotFound</h1>
                </div>

                <b-card bg-variant="light">
                    <div class="">
                        <h4 class="text-center m-4">お探しのページは存在しません</h4>
                        <h6 class="m-2">
                            10秒後、自動的にトップページへ戻ります。<br><br>
                        </h6>
                    </div>

                    <br>
                    <div class="border-bottom" />
                    <br>

                    <b-button class="w-100" :to="{ path: '/' }" variant="outline-primary">トップページへ</b-button>

                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    data() {
        return {}
    },
    mounted() {
        setTimeout(() => {
            this.$router.replace('/')
        }, 10000)
    }
}
</script>
