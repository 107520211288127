<template>
    <div class="lineworks-setting">
        <div class="container">
            <div class="m-5">

                <div class="title p-5">
                    <h3 class="text-center">クラウドサイン - LINE WORKS連携<br>通知ボット管理</h3>
                    <h3 class="text-center mb-4">連携設定</h3>
                </div>

                <b-card bg-variant="light">
                    <div class="mb-2">
                        <h4 class="text-center mt-4 mb-5">通知ボット連携設定</h4>
                        <h6 class="m-2" v-if="logding && !registered">
                            <b-link href="https://developers.worksmobile.com/jp/?lang=ja" class="text-primary" target="_blank">LINE WORKS Developer Console</b-link>より必要項目を確認し、下記へ入力してください。<br><br>
                        </h6>
                        <h6 class="m-2 text-danger mt-4" v-if="logding && registered">
                            API連携は完了しています。<br>
                            API設定を更新する場合は再度<b-link href="https://developers.worksmobile.com/jp/?lang=ja" class="text-primary" target="_blank">LINE WORKS Developer Console</b-link>を確認の上、各項目を入力してください。<br><br>
                            クラウドサインHook URL<br>
                            {{hookUrl}}<br><br>
                        </h6>
                        <h6 class="m-2">詳細は<b-link href="https://help.cloudsign.jp/ja/articles/5055112" class="text-primary" target="_blank">連携マニュアル</b-link>をご覧ください。</h6>
                    </div>

                    <br>
                    <div class="border-bottom" />
                    <br>

                    <b-form @submit.prevent="postLineworksSetting">

                        <b-form-group id="input-group-developers-account" label="LINE WORKS Developers Account" label-for="input-developers-account">
                            <b-form-input id="input-developers-account" v-model="developersAccount" :state="developersAccountCheck" type="text" placeholder="例: user_name@example"></b-form-input>
                            <b-form-invalid-feedback id="input-developers-account-invalid-feedback">{{developersAccountMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-developers-account-valid-feedback">{{developersAccountMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-domain-id" label="Domain ID" label-for="input-domain-id">
                            <b-form-input id="input-domain-id" v-model="domainId" :state="domainIdCheck" type="text" placeholder="例: 12345678"></b-form-input>
                            <b-form-invalid-feedback id="input-domain-id-invalid-feedback">{{domainIdMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-domain-id-valid-feedback">{{domainIdMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-client-id" label="Client ID" label-for="input-client-id">
                            <b-form-input id="input-client-id" v-model="clientId" :state="clientIdCheck" type="text" placeholder="例: abCd1EFgh2Ijk"></b-form-input>
                            <b-form-invalid-feedback id="input-client-id-invalid-feedback">{{clientIdMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-client-id-valid-feedback">{{clientIdMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-client-secret" label="Client Secret" label-for="input-client-secret">
                            <b-form-input id="input-client-secret" v-model="clientSecret" :state="clientSecretCheck" type="text" placeholder="例: abCd1EFgh2Ijk"></b-form-input>
                            <b-form-invalid-feedback id="input-client-secret-invalid-feedback">{{clientSecretMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-client-secret-valid-feedback">{{clientSecretMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-service-account" label="Service Account" label-for="input-service-account">
                            <b-form-input id="input-service-account" v-model="serviceAccount" :state="serviceAccountCheck" type="text" placeholder="例: abCd1EFgh2Ijk@example"></b-form-input>
                            <b-form-invalid-feedback id="input-service-account-invalid-feedback">{{serviceAccountMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-service-account-valid-feedback">{{serviceAccountMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-private-key" label="Private Key" label-for="input-private-key">
                            <b-form-textarea id="input-private-key" v-model="privateKey" :state="privateKeyCheck" type="text" :placeholder="privateKeyPlaceholder()" rows="6" max-rows="6"></b-form-textarea>
                            <b-form-invalid-feedback id="input-private-key-invalid-feedback">{{privateKeyMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-private-key-valid-feedback">{{privateKeyMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-oauth-scopes" label="OAuth Scopes" label-for="input-oauth-scopes">
                            <b-form-checkbox-group v-model="oAuthScopes" id="input-oauth-scopes" :state="oAuthScopesCheck">
                                <!-- <b-form-checkbox v-for="option in oAuthScopesOptions1" :key="option.value" :value="option.value" inline>{{ option.text }}</b-form-checkbox><br>
                                <b-form-checkbox v-for="option in oAuthScopesOptions2" :key="option.value" :value="option.value" inline>{{ option.text }}</b-form-checkbox><br> -->
                                <b-form-checkbox v-for="option in oAuthScopesOptions3" :key="option.value" :value="option.value" disabled inline>{{ option.text }}</b-form-checkbox>
                                <!-- <b-form-checkbox v-for="option in oAuthScopesOptions4" :key="option.value" :value="option.value" inline>{{ option.text }}</b-form-checkbox><br>
                                <b-form-checkbox v-for="option in oAuthScopesOptions5" :key="option.value" :value="option.value" inline>{{ option.text }}</b-form-checkbox><br>
                                <b-form-checkbox v-for="option in oAuthScopesOptions6" :key="option.value" :value="option.value" inline>{{ option.text }}</b-form-checkbox><br>
                                <b-form-checkbox v-for="option in oAuthScopesOptions7" :key="option.value" :value="option.value" inline>{{ option.text }}</b-form-checkbox><br>
                                <b-form-checkbox v-for="option in oAuthScopesOptions8" :key="option.value" :value="option.value" inline>{{ option.text }}</b-form-checkbox><br>
                                <b-form-checkbox v-for="option in oAuthScopesOptions9" :key="option.value" :value="option.value" inline>{{ option.text }}</b-form-checkbox><br>
                                <b-form-checkbox v-for="option in oAuthScopesOptions10" :key="option.value" :value="option.value" inline>{{ option.text }}</b-form-checkbox><br>
                                <b-form-checkbox v-for="option in oAuthScopesOptions11" :key="option.value" :value="option.value" inline>{{ option.text }}</b-form-checkbox><br>
                                <b-form-checkbox v-for="option in oAuthScopesOptions12" :key="option.value" :value="option.value" inline>{{ option.text }}</b-form-checkbox> -->
                            </b-form-checkbox-group>
                            <b-form-invalid-feedback id="input-oauth-scopes-invalid-feedback" :state="oAuthScopesCheck">{{oAuthScopesMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-oauth-scopes-valid-feedback" :state="oAuthScopesCheck">{{oAuthScopesMassage}}</b-form-valid-feedback>
                        </b-form-group>
                        <b-button class="w-100" v-bind:disabled="postSubmitCheck" type="submit" variant="primary" v-if="!registered">連携設定 登録</b-button>
                        <b-button class="w-100" v-bind:disabled="postSubmitCheck" type="submit" variant="primary" v-else>連携設定 更新</b-button>
                    </b-form>
                    <b-button class="w-100 mt-3" variant="outline-primary" @click="showDeleteConfirmModal" v-if="registered">連携設定 削除</b-button>
                </b-card>
            </div>
            <b-modal ref="delete-confirm" hide-footer title="連携設定削除確認">
                <div class="d-block">
                    <h6 class="text-danger">
                        以下の内容をご確認の上、削除を行なってください。<br><br>
                        1.下記のボット・データが削除されます。<br>
                        - LINE WORKSのボット一覧から『クラウドサイン通知』ボット<br>
                        - 『クラウドサイン通知』で設定した通知設定<br>
                        - LINE WORKSの連携設定情報<br><br>
                        2.削除されたボット・データの復元はできません。<br><br>
                        3.クラウドサインのHook URL設定は連携マニュアルを見て解除をお願いします。<br><br>
                        同意の上で削除する場合は以下に『削除する』と入力した上で「連携設定削除」ボタンをクリックしてください。
                    </h6>
                </div>
                <br>
                <b-form @submit.prevent="deleteLineworksSetting">
                    <b-form-group id="delete-confirm" label-for="delete-confirm">
                        <b-form-input id="delete-confirm" v-model="deleteConfirm" :state="deleteConfirmCheck" type="text" placeholder="削除する"></b-form-input>
                    </b-form-group>
                    <b-button class="w-100" v-bind:disabled="deleteSubmitCheck" type="submit" variant="primary" @click="hideDeleteConfirmModal">連携設定 削除</b-button>
                </b-form>
            </b-modal>
        </div>
    </div>
</template>

<script>
export default {
    name: "LineworksSettingApi2",
    data() {
        return {
            developersAccount: '',
            developersAccountMassage: '',
            domainId: '',
            domainIdMassage: '',
            clientId: '',
            clientIdMassage: '',
            clientSecret: '',
            clientSecretMassage: '',
            serviceAccount: '',
            serviceAccountMassage: '',
            privateKey: '',
            privateKeyMassage: '',
            oAuthScopes: ['bot', 'bot.read'],
            oAuthScopesOptions: [
                // 'audit.read',
                // 'board', 'board.read',
                'bot', 'bot.read'
                // 'calendar', 'calendar.read',
                // 'contact', 'contact.read',
                // 'directory', 'directory.read',
                // 'file', 'file.read',
                // 'group', 'group.folder', 'group.folder.read', 'group.read',
                // 'mail', 'mail.read',
                // 'orgunit', 'orgunit.read',
                // 'security.external-browser', 'security.external-browser.read',
                // 'user', 'user.email.read', 'user.profile.read', 'user.read'
            ],
            // oAuthScopesOptions1: [
            //     { text: 'audit.read', value: 'audit.read' }
            // ],
            // oAuthScopesOptions2: [
            //     { text: 'board', value: 'board' },
            //     { text: 'board.read', value: 'board.read' }
            // ],
            oAuthScopesOptions3: [
                { text: 'bot', value: 'bot' },
                { text: 'bot.read', value: 'bot.read' }
            ],
            // oAuthScopesOptions4: [
            //     { text: 'calendar', value: 'calendar' },
            //     { text: 'calendar.read', value: 'calendar.read' }
            // ],
            // oAuthScopesOptions5: [
            //     { text: 'contact', value: 'contact' },
            //     { text: 'contact.read', value: 'contact.read' }
            // ],
            // oAuthScopesOptions6: [
            //     { text: 'directory', value: 'directory' },
            //     { text: 'directory.read', value: 'directory.read' }
            // ],
            // oAuthScopesOptions7: [
            //     { text: 'file', value: 'file' },
            //     { text: 'file.read', value: 'file.read' }
            // ],
            // oAuthScopesOptions8: [
            //     { text: 'group', value: 'group' },
            //     { text: 'group.folder', value: 'group.folder' },
            //     { text: 'group.folder.read', value: 'group.folder.read' },
            //     { text: 'group.read', value: 'group.read' }
            // ],
            // oAuthScopesOptions9: [
            //     { text: 'mail', value: 'mail' },
            //     { text: 'mail.read', value: 'mail.read' }
            // ],
            // oAuthScopesOptions10: [
            //     { text: 'orgunit', value: 'orgunit' },
            //     { text: 'orgunit.read', value: 'orgunit.read' }
            // ],
            // oAuthScopesOptions11: [
            //     { text: 'security.external-browser', value: 'security.external-browser' },
            //     { text: 'security.external-browser.read', value: 'security.external-browser.read' },
            // ],
            // oAuthScopesOptions12: [
            //     { text: 'user', value: 'user' },
            //     { text: 'user.email.read', value: 'user.email.read' },
            //     { text: 'user.profile.read', value: 'user.profile.read' },
            //     { text: 'user.read', value: 'user.read' }
            // ],
            oAuthScopesMassage: '',
            deleteConfirm: '',
            registered: false,
            hookUrl: '',
            logding: false
        }
    },
    computed: {
        developersAccountCheck() {
            return this.developersAccountValidation()
        },
        domainIdCheck() {
            return this.domainIdValidation()
        },
        clientIdCheck() {
            return this.clientIdValidation()
        },
        clientSecretCheck() {
            return this.clientSecretValidation()
        },
        serviceAccountCheck() {
            return this.serviceAccountValidation()
        },
        privateKeyCheck() {
            return this.privateKeyValidation()
        },
        oAuthScopesCheck() {
            return this.oAuthScopesValidation()
        },
        postSubmitCheck() {
            return this.postSubmitValidation()
        },
        deleteConfirmCheck() {
            return this.deleteConfirmValidation()
        },
        deleteSubmitCheck() {
            return this.deleteSubmitValidation()
        }
    },
    methods: {
        getLineworksSetting(){
            console.log(this.$store.state.user)

            if(this.$store.state.user){
                let url = process.env.VUE_APP_API_BASE_URL + '/lineworks-setting/get'

                let data = {
                    'adminUserId': this.$store.state.user.email
                }

                this.axios.post(url, data)
                .then(function (response) {
                    console.log(response);
                    if (response['data']['result'] && response['data']['message']){
                        this.registered = true
                        this.hookUrl = response['data']['message']
                    }
                    else if (response['data']['message']){
                        this.showAlertDanger('通知ボット連携設定取得に失敗しました。管理者へお問合せください。')
                        this.$router.replace('/signout')
                    }else{
                        this.registered = false
                    }

                    this.logding = true

                }.bind(this))
                .catch(function (err) {
                    console.log(err);
                    this.showAlertDanger('通知ボット連携設定取得に失敗しました。管理者へお問合せください。')
                    // this.$router.replace('/signout')
                    this.registered = false
                }.bind(this))
            }
        },
        postLineworksSetting() {
            let url = process.env.VUE_APP_API_BASE_URL + '/lineworks-setting/post'

            let data = {
                'adminUserId': this.$store.state.user.email,
                'developersAccount': this.developersAccount,
                'domainId': this.domainId,
                'clientId': this.clientId,
                'clientSecret': this.clientSecret,
                'serviceAccount': this.serviceAccount,
                'privateKey': this.privateKey,
                'oAuthScopes': this.oAuthScopes.join(',')
            }

            this.axios.post(url, data)
            .then(function (response) {
                console.log(response);

                if (response['data']['result']){
                    if(!this.registered){
                        this.$router.replace('/lineworks-setting-result')
                        // this.showAlertSuccess('通知ボット連携設定の登録が完了しました。')
                    }
                    else{
                        this.showAlertSuccess('通知ボット連携設定の更新が完了しました。')
                    }
                }
                else {
                    if(!this.registered){
                        if(response['data']['message'] == '通知ボットが既に存在します。 別アカウントで、既に作成されている可能性があります。'){
                            this.showAlertDanger('通知ボットが既に存在します。 別アカウントで、既に作成されている可能性があります。')
                        }
                        else{
                            this.showAlertDanger('通知ボット連携設定の登録に失敗しました。連携マニュアルをご確認の上、再度設定をお願いいたします。')
                        }
                    }
                    else{
                        this.showAlertDanger('通知ボット連携設定の更新に失敗しました。連携マニュアルをご確認の上、再度設定をお願いいたします。')
                    }
                }

                this.getLineworksSetting()
            }.bind(this))
            .catch(function (err) {
                console.log(err);
                if(!this.registered){
                    this.showAlertDanger('通知ボット連携設定の登録に失敗しました。連携マニュアルをご確認の上、再度設定をお願いいたします。')
                }
                else{
                    this.showAlertDanger('通知ボット連携設定の更新に失敗しました。連携マニュアルをご確認の上、再度設定をお願いいたします。')
                }
            }.bind(this))

            this.developersAccount = ''
            this.domainId = ''
            this.clientId = ''
            this.clientSecret = ''
            this.serviceAccount = ''
            this.privateKey = ''
            this.oAuthScopes = ['bot', 'bot.read']
        },
        deleteLineworksSetting() {
            let url = process.env.VUE_APP_API_BASE_URL + '/lineworks-setting/delete'

            let data = {
                'adminUserId': this.$store.state.user.email
            }

            this.axios.post(url, data)
            .then(function (response) {
                console.log(response);

                if (response['data']['result']){
                    this.showAlertSuccess('通知ボット連携設定の削除が完了しました。')
                }
                else {
                    this.showAlertDanger('通知ボット連携設定の削除に失敗しました。連携マニュアルをご確認の上、再度削除をお願いいたします。')
                }

                this.getLineworksSetting()
            }.bind(this))
            .catch(function (err) {
                console.log(err);
                this.showAlertDanger('通知ボット連携設定の削除に失敗しました。連携マニュアルをご確認の上、再度削除をお願いいたします。')
            }.bind(this))

            this.deleteConfirm = ''
        },
        developersAccountValidation() {
            this.developersAccountMassage = '問題なし'
            let state = true

            let regex = /[!-~]{1,}/;

            if (!this.developersAccount) {
                this.developersAccountMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.developersAccount)) {
                this.developersAccountMassage = 'フォーマットが不正です'
                state = false
            }
            return state
        },
        domainIdValidation() {
            this.domainIdMassage = '問題なし'
            let state = true

            let regex = /[0-9]{1,}/;

            if (!this.domainId) {
                this.domainIdMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.domainId)) {
                this.domainIdMassage = 'フォーマットが不正です'
                state = false
            }
            return state
        },
        clientIdValidation() {
            this.clientIdMassage = '問題なし'
            let state = true

            let regex = /[!-~]{1,}/;

            if (!this.clientId) {
                this.clientIdMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.clientId)) {
                this.clientIdMassage = 'フォーマットが不正です'
                state = false
            }
            return state
        },
        clientSecretValidation() {
            this.clientSecretMassage = '問題なし'
            let state = true

            let regex = /[!-~]{1,}/;

            if (!this.clientSecret) {
                this.clientSecretMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.clientSecret)) {
                this.clientSecretMassage = 'フォーマットが不正です'
                state = false
            }
            return state
        },
        serviceAccountValidation() {
            this.serviceAccountMassage = '問題なし'
            let state = true

            let regex = /[!-~]{1,}/;

            if (!this.serviceAccount) {
                this.serviceAccountMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.serviceAccount)) {
                this.serviceAccountMassage = 'フォーマットが不正です'
                state = false
            }
            return state
        },
        privateKeyValidation() {
            this.privateKeyMassage = '問題なし'
            let state = true

            let regex = /[!-~]{1,}/;

            if (!this.privateKey) {
                this.privateKeyMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.privateKey)) {
                this.privateKeyMassage = 'フォーマットが不正です'
                state = false
            }
            return state
        },
        oAuthScopesValidation() {
            this.oAuthScopesMassage = '問題なし'
            let state = true

            for(let i = 0; i < this.oAuthScopes.length; i++){
                if (!this.oAuthScopesOptions.includes(this.oAuthScopes[i])) {
                    this.oAuthScopesMassage = '値が不正です'
                    state = false
                    break
                }
            }
            return state
        },
        postSubmitValidation() {
            let disabled = true

            if (this.developersAccountValidation() && this.domainIdValidation() && this.clientIdValidation() && this.clientSecretValidation() && this.serviceAccountValidation() && this.privateKeyValidation() && this.oAuthScopesValidation()) {
                disabled = false
            }

            return disabled
        },
        deleteConfirmValidation() {
            let state = true

            if (this.deleteConfirm != '削除する') {
                state = false
            }
            return state
        },
        deleteSubmitValidation() {
            let disabled = true

            if (this.deleteConfirmValidation()) {
                disabled = false
            }

            return disabled
        },
        showDeleteConfirmModal() {
            this.$refs['delete-confirm'].show()
        },
        hideDeleteConfirmModal() {
            this.$refs['delete-confirm'].hide()
        },
        privateKeyPlaceholder() {
            let placeholder = "" +
            "例: \n" +
            "-----BEGIN PRIVATE KEY-----\n" +
            "MIIEvQIBADANBgkdskiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQChnpJnDpbkIKWQ\n" +
            "sN+GHLSPyCb7jMwagObwoqFMqyqgLDBMTEZmSVP30Cph6F1AKByLNBDFnMJp4gIV\n" +
            "lL9k1qyUiWGpOpQwoLm1hmHoHSBaefewasnlqRdkPkIW+UVqE/iYB7mpVSpg6t61\n" +
            "YjknKwQiEpBiwFTxnYbWWosZUcj8NkzVlqKZEEG46JHSJ3vnYAeyZKDZxFw4PnfU\n" +
            "kFdQNtI5it/PMRZ21e2OHKcYYDNW+qkLoW+tzezoOjF0xi44XMcrKFgU9wBC8H3U\n" +
            "gRaROzHScmzaOETm1BaNwb1HZ72HX4/EJPRC/UIU1r+6RLqh83fv4sLnpTO1d4ci\n" +
            "n9yiXguPAgMBAAECggEAU1k52deadRVhDRuKStmm7LemEvhrkgeEEfltAeMMGopv\n" +
            "Zgm4DKwoV5bNJKz22k1VWxzAwYi0YnKh7ItHoTNs0TWAXgvNvL5Gx6zD9TaAGbkN\n" +
            "SJFoGb54R8QrxTlMaQZfn9fdeadQGydFL3m2S9LNUN0OBT93DxvUG9uZ/LbG1fo8\n" +
            "ovVEZfL/ufikJYK2TUBCOtB5fyYWWXUHxt0b56X4W7oE2/3CkpO6ldZP7wwBJ424\n" +
            "/peMHK9zDGwj+LOJqbfEHNhEZ8GL+tEJW4l/+N2wCMlvKQEyU/QnN3NbV3A0KcBS\n" +
            "9r6OR28roZEkVkLbTdgH4rdaewcEZsSsqLSPVldLoQKBgQD5+435a8E4ZQlgAHWn\n" +
            "WFoK/rMqN+FMQ/IH9IMxf1DCf4dLYb1AoRgltAtWYizp17ZZA8hBQt0B3v20Xg+k\n" +
            "QNU3bcdNadK3nB0jX1dd4egS6KC0pfRSFrH6HeoxWotE/BcmS9t/9eLVsKRsomy8\n" +
            "naY+0KFhWsWBdAvbBeNw+nsbNwKBgQClgoEwlUeZz008GqE2pcmu/tdZJ9YF4j7x\n" +
            "+V+cssDi26v8HwW21n8Z5rHo4vNP5K58kNmpJtm2TdI3dVYZR8rWtRb8GF14/SLK\n" +
            "32zs4ouiWyqwYlCtteRPZolIINM7rSrGhWEQRghrJMM5TJkY/daQfrq0mvjvbZoA\n" +
            "c1FFxuQuaQKBgQCxuR8xwWMIdgSZRRpgVBcIFBZKoJnDf9bVwwd2rD49WS9V73H+\n" +
            "PcmOHJem3Xty8NKKLH81rpSznNagYfMa4qDznE+CgQa3VPz+Re02KMK2lr6SUB4I\n" +
            "MmxsFtKGLgovjE3lAy9VpPmU1NXBCiTmX+puZCMntMIQC/HV0DToAJx/NwKBgDEo\n" +
            "XsiYPejwCkeEvUcm3I0uQbXIQCgFN57U61H2QZXfmuhKKn9Qm/WMcGD0L0IWcMCs\n" +
            "oQk9ccJUwNAZ+JWPni5BIO+FKRYLUS8iRw7ghoqVO0uiF8ZQVNxEXxfeL/70gtTt\n" +
            "vpN/yXZRN+6n0nz6v6SxDzEfKjqquefSWFw6I4GpAoGADECcdf2YvtxturSU8Ouj\n" +
            "qrHbTjOVPq+dcREJv4Fu9nWwcCWGUlXOv+mdeQf2AYmzB86sEtfX7P+mv9/x5c1K\n" +
            "PobAOC4qTI72gBfg8vvKuxBH/yZmFf7w0mPslmpbD9S9+wirE8ato3Re0owczmTU\n" +
            "SSNybdQAm9h0dejJmk4T6lA=\n" +
            "-----END PRIVATE KEY-----"

            return placeholder
        }
    },
    watch:  {
        '$store.state.user': function(){
            if (this.$store.state.user){
                this.getLineworksSetting()
            }
        }
    }
}
</script>
