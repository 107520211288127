<template>
    <div class="link-router"></div>
</template>

<script>
export default {
    name: "LinkRouter",
    data() {
        return {
        }
    },
    mounted() {
        if (localStorage.getItem('path')){
            this.$router.replace(localStorage.getItem('path'))
        }else{
            this.$router.replace('/')
        }
    }
};
</script>
