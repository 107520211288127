<template>
    <div class="reset-password-view">
        <ResetPassword />
    </div>
</template>

<script>
import ResetPassword from "@/components/ResetPassword.vue";

export default {
    name: "ResetPasswordView",
    components: {
        ResetPassword,
    },
};
</script>
