<template>
    <div class="header">
        <b-navbar toggleable="lg" type="dark" variant="primary" fixed="top">
            <b-navbar-brand :to="{ path: '/' }">クラウドサイン - LINE WORKS連携 通知ボット管理</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav v-if="signinSession">
                <b-navbar-nav class="ml-auto">
                    <b-nav-item href="https://help.cloudsign.jp/ja/articles/5055112" target=”_blank” right>連携マニュアル</b-nav-item>
                    <b-nav-item href="mailto:relation@cloudsign.jp" right>お問合せ</b-nav-item>
                    <b-nav-item-dropdown right>
                        <template #button-content>
                                    <em>{{name}} | {{email}}</em>
                        </template>
                        <b-dropdown-item :to="{ path: '/signout' }">ログアウト</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>

            <b-collapse id="nav-collapse" is-nav v-else>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item href="mailto:relation@cloudsign.jp" right>お問合せ</b-nav-item>
                    <b-nav-item :to="{ path: '/signin' }" right>ログイン</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            name: '',
            email: ''
        }
    },
    computed: {
        signinSession() {
            return this.signinSessionCheck()
        }
    },
    methods: {
        signinSessionCheck() {
            let session = false

            if (this.$store.state.user) {
                this.name = this.$store.state.user.name
                this.email = this.$store.state.user.email
                session = true
            }

            return session
        }
    }
};
</script>
