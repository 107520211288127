<template>
    <div id="app">
        <Header/>
        <NotificationAlert/>
        <router-view/>
        <Footer/>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import NotificationAlert from "@/components/NotificationAlert.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: "App",
    components: {
        Header,
        NotificationAlert,
        Footer
    }
};
</script>
