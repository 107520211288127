<template>
    <div class="link-router-view">
        <LinkRouter />
    </div>
</template>

<script>
import LinkRouter from "@/components/LinkRouter.vue";

export default {
    name: "LinkRouterView",
    components: {
        LinkRouter,
    },
};
</script>
