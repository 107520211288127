<template>
    <div class="lineworks-setting-view">
        <LineworksSetting />
    </div>
</template>

<script>
import LineworksSetting from "@/components/LineworksSetting.vue";

export default {
    name: "LineworksSettingView",
    components: {
        LineworksSetting,
    },
};
</script>
