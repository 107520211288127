<template>
    <div class="signin">
        <div class="container">
            <div class="m-5">

                <div class="title p-5">
                    <h3 class="text-center">クラウドサイン - LINE WORKS連携<br>通知ボット管理</h3>
                    <h3 class="text-center mb-4">ログイン</h3>
                </div>

                <b-card bg-variant="light">
                    <div class="text-right">
                        <span>※クラウドサインのアカウントとは異なります</span>
                    </div>
                    <b-form @submit.prevent="signin">

                        <b-form-group id="input-group-email" label="メールアドレス" label-for="input-email">
                            <b-form-input id="input-email" v-model="email" type="email" placeholder="メールアドレス"></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-password" label="パスワード" label-for="input-password">
                            <b-form-input id="input-password" v-model="password" type="password" placeholder="パスワード"></b-form-input>
                        </b-form-group>

                        <b-button class="w-100" type="submit" variant="primary">ログイン</b-button>
                    </b-form>

                    <div class="text-right mt-3 mr-2">
                        <b-link :to="{ path: '/reset-password' }" class="text-primary">パスワードを忘れた方</b-link>
                    </div>

                    <br>
                    <div class="border-bottom" />
                    <br>

                    <h4 class="text-center mb-4">初めて設定する方はこちら</h4>
                    <b-button class="w-100" :to="{ path: '/signup' }" variant="outline-primary">新規アカウント登録</b-button>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Signin',
    data() {
        return {
            email: '',
            password: ''
        }
    },
    mounted() {
        localStorage.removeItem('email')
        localStorage.removeItem('path')
    },
    computed: {
    },
    methods: {
        signin() {
            this.$cognito.signin(this.email, this.password)
                .then(result => {
                    console.log(result)
                    this.$router.replace('/')
                })
                .catch(err => {
                    console.log(err)
                    this.showAlertDanger('ログインに失敗しました。 メールアドレス 又は パスワードが違います。')
                    this.email = ''
                    this.password = ''
                })
        }
    }
}
</script>
