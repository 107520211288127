<template>
    <div class="reset-password-confirm">
        <div class="container">
            <div class="m-5">

                <div class="title p-5">
                    <h3 class="text-center">クラウドサイン - LINE WORKS連携<br>通知ボット管理</h3>
                    <h3 class="text-center mb-4">パスワード再設定</h3>
                </div>

                <b-card bg-variant="light">
                    <div class="mb-2">
                        <h4 class="text-center mt-4 mb-5">登録メールアドレスへ<br>確認コードを送信しました</h4>
                        <h6 class="m-2">
                            『no-reply@verificationemail.com』より確認コードを含むメールが届きます。<br><br> メール内容を確認し、『登録メールアドレス』と『確認コード』を入力してください。
                            <br><br> メールが届かない場合は『再送信』を選択してください。
                        </h6>
                    </div>

                    <br>
                    <div class="border-bottom" />
                    <br>

                    <b-form @submit.prevent="resetPassword">

                        <b-form-group id="input-group-email" label="メールアドレス" label-for="input-email">
                            <b-form-input id="input-email" v-model="email" :state="emailCheck" type="email" placeholder="メールアドレス"></b-form-input>
                            <b-form-invalid-feedback id="input-email-invalid-feedback">{{emailMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-email-valid-feedback">{{emailMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-code" label="確認コード" label-for="input-code">
                            <b-form-input id="input-code" v-model="code" :state="codeCheck" type="text" placeholder="確認コード"></b-form-input>
                            <b-form-invalid-feedback id="input-code-invalid-feedback">{{codeMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-code-valid-feedback">{{codeMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-password" label="パスワード" label-for="input-password">
                            <b-form-input id="input-password" v-model="password" :state="passwordCheck" type="password" placeholder="パスワード"></b-form-input>
                            <b-form-invalid-feedback id="input-password-invalid-feedback">{{passwordMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-password-valid-feedback">{{passwordMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-password-confirm" label="パスワード確認" label-for="input-password-confirm">
                            <b-form-input id="input-password-confirm" v-model="passwordConfirm" :state="passwordConfirmCheck" type="password" placeholder="パスワード確認"></b-form-input>
                            <b-form-invalid-feedback id="input-password-confirm-invalid-feedback">{{passwordConfirmMassage}}</b-form-invalid-feedback>
                            <b-form-valid-feedback id="input-password-confirm-valid-feedback">{{passwordConfirmMassage}}</b-form-valid-feedback>
                        </b-form-group>

                        <b-button class="w-100" v-bind:disabled="submitCheck" type="submit" variant="primary">パスワード再設定</b-button>
                    </b-form>

                    <br>
                    <div class="border-bottom" />
                    <br>

                    <h4 class="text-center mb-4">メールが届かない場合</h4>
                    <b-button class="w-100" @click="resendCode" variant="outline-primary">再送信</b-button>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ResetPasswordConfirm',
    data() {
        return {
            email: '',
            emailMassage: '',
            code: '',
            codeMassage: '',
            password: '',
            passwordMassage: '',
            passwordConfirm: '',
            passwordConfirmMassage: '',
            emailResend: ''
        }
    },
    mounted() {
        this.email = localStorage.getItem('email');
        this.emailResend = localStorage.getItem('email');
    },
    computed: {
        emailCheck() {
            return this.emailValidation()
        },
        codeCheck() {
            return this.codeValidation()
        },
        passwordCheck() {
            return this.passwordValidation()
        },
        passwordConfirmCheck() {
            return this.passwordConfirmValidation()
        },
        submitCheck() {
            return this.submitValidation()
        }
    },
    methods: {
        resetPassword() {
            this.$cognito.resetPassword(this.email, this.password, this.code)
                .then(result => {
                    console.log(result)
                    localStorage.removeItem('email')
                    this.showAlertSuccess('パスワードの再設定が完了しました。')
                    this.$router.replace('/signin')
                })
                .catch(err => {
                    console.log(err)
                    this.showAlertDanger('パスワードの再設定に失敗しました。 メールアドレス 又は 確認コードが違います。')
                })
        },
        resendCode() {
            this.$cognito.forgetPassword(this.emailResend)
                .then(result => {
                    console.log(result)
                    this.showAlertSuccess('確認コードを再送信しました。')
                })
                .catch(err => {
                    console.log(err)
                    this.showAlertDanger('確認コードの再送信に失敗しました。メールアドレスを入力した上で、再送信をクリックしてください。')
                })
        },
        emailValidation() {
            this.emailMassage = '問題なし'
            let state = true

            let regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

            if (!this.email) {
                this.emailMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.email)) {
                this.emailMassage = 'メールアドレスのフォーマットが不正です'
                state = false
            }

            return state
        },
        codeValidation() {
            this.codeMassage = '問題なし'
            let state = true

            let regex = /\d{6}/;

            if (!this.code) {
                this.codeMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.code)){
                this.codeMassage = '半角数字6桁の確認コードを入力してください'
                state = false
            }

            return state
        },
        passwordValidation() {
            this.passwordMassage = '問題なし'
            let state = true

            let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[!-~]{8,100}$/;

            if (!this.password) {
                this.passwordMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.password)){
                this.passwordMassage = '半角小文字、大文字、数字をそれぞれ1種類以上含む 8文字以上のパスワードを入力してください'
                state = false
            }

            return state
        },
        passwordConfirmValidation() {
            this.passwordConfirmMassage = '問題なし'
            let state = true

            let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[!-~]{8,100}$/;

            if (!this.passwordConfirm) {
                this.passwordConfirmMassage = '必須入力です'
                state = false
            }
            else if (!regex.test(this.passwordConfirm)){
                this.passwordConfirmMassage = '半角小文字、大文字、数字をそれぞれ1種類以上含む 8文字以上のパスワードを入力してください'
                state = false
            }
            else if (this.password != this.passwordConfirm){
                this.passwordConfirmMassage = 'パスワードが一致しません'
                state = false
            }

            return state
        },
        submitValidation() {
            let disabled = true
            if (this.emailValidation() && this.codeValidation() && this.passwordValidation() && this.passwordConfirmValidation()) {
                disabled = false
            }

            return disabled
        }
    }
}
</script>
