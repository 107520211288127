/* vue.js */
import Vue from 'vue'
import Router from 'vue-router'
import cognito from '../cognito'
import store from '../store'

/* bootstrap */
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

/* styles.css */
import '../assets/css/styles.css'

/* views */
import Signin from '../views/SigninView.vue'
import Signup from '../views/SignupView.vue'
import SignupConfirm from '../views/SignupConfirmView.vue'
import ResetPassword from '../views/ResetPasswordView.vue'
import ResetPasswordConfirm from '../views/ResetPasswordConfirmView.vue'
import LineworksSetting from '../views/LineworksSettingView.vue'
import LineworksSettingResult from '../views/LineworksSettingResultView.vue'
import LinkRouter from'../views/LinkRouterView.vue'
import NotFound from '../views/NotFoundView.vue'

/* mixin */
import alert from '../mixin/alert.js'

Vue.use(Router)
Vue.use(BootstrapVue)
Vue.mixin(alert)

const signout = (to, from, next) => {
    cognito.signout()
    next('/signin')
}

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'LineworksSetting',
            component: LineworksSetting,
            meta: { requiredAuth: true }
        },
        {
            path: '/lineworks-setting-result',
            name: 'LineworksSettingResult',
            component: LineworksSettingResult,
            meta: { requiredAuth: true }
        },
        {
            path: '/signin',
            name: 'Signin',
            component: Signin,
            meta: { notRequiredAuth: true }
        },
        {
            path: '/signup',
            name: 'Signup',
            component: Signup,
            meta: { notRequiredAuth: false }
        },
        {
            path: '/signup-confirm',
            name: 'SignupConfirm',
            component: SignupConfirm,
            meta: { notRequiredAuth: false }
        },
        {
            path: '/reset-password',
            name: 'ResetPassword',
            component: ResetPassword,
            meta: { notRequiredAuth: false }
        },
        {
            path: '/reset-password-confirm',
            name: 'ResetPasswordConfirm',
            component: ResetPasswordConfirm,
            meta: { notRequiredAuth: false }
        },
        {
            path: '/link-router',
            name: 'LinkRouter',
            component: LinkRouter,
            meta: { notRequiredAuth: false }
        },
        {
            path: '/signout',
            beforeEnter: signout,
        },
        {
            path: '*',
            component: NotFound
        }
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

router.beforeResolve(async (to, from, next) => {
    cognito.isAuthenticated()
        .then(session => { // eslint-disable-line
            console.log("セッション有り")
            const token = session.idToken.jwtToken
            // get attribute.
            cognito.getAttribute()
                .then(result => {
                    let user = {}
                    for (let v of result) {
                        user[v.getName()] = v.getValue()
                    }
                    user['token'] = token
                    store.commit('setUser', user)
                })
                .catch(error => {
                    store.commit('setUser', null)
                    console.log(error)
                    signout(to, from, next)
                })
            if (to.matched.some(record => record.meta.notRequiredAuth)) {
                next({
                    path: '/',
                })
            }
        })
        .catch(error => { // eslint-disable-line
            store.commit('setUser', null)
            console.log("セッション無し")
            if (to.matched.some(record => record.meta.requiredAuth)) {
                console.log("signinにいくはず")
                next({
                    path: '/signin',
                    query: { redirect: to.fullPath }
                })
            }
        })
    next()
})

export default router
