<template>
    <div class="lineworks-setting-result-view">
        <LineworksSettingResult />
    </div>
</template>

<script>
import LineworksSettingResult from "@/components/LineworksSettingResult.vue";

export default {
    name: "LineworksSettingResultView",
    components: {
        LineworksSettingResult,
    },
};
</script>
