<template>
    <div class="signup-confirm-view">
        <SignupConfirm />
    </div>
</template>

<script>
import SignupConfirm from "@/components/SignupConfirm.vue";

export default {
    name: "SignupConfirmView",
    components: {
        SignupConfirm,
    },
};
</script>
